<template>
  <div id="loadcontainer">
    <div id="spinner">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 99.7 99.7"
        style="enable-background:new 0 0 99.7 99.7;"
        xml:space="preserve"
      >
        <path
          class="st0"
          d="M49.9,0v9.7C72,9.7,90,27.7,90,49.9S72,90,49.9,90C27.7,90,9.8,72.1,9.7,50H0c0.1,27.5,22.4,49.7,49.9,49.7
c27.5,0,49.9-22.3,49.9-49.9S77.4,0,49.9,0z"
        />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "Loader"
};
</script>
<style lang="css">
#loadcontainer p {
  text-align: center;
  padding-bottom: 5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
#spinner {
  margin: 0 auto;
  animation: spin 2s linear infinite;
  width: 100px;
  height: 100px;
}

.st0 {
  fill: #154995;
}
</style>