<template>
  <div>
    <Loader v-if="loading" />
    <div v-if="!loading">
      <h1>{{ pool.title }}</h1>
      <div class="stickybar">
        <b-alert v-if="pool.alert" show dismissible variant="important">{{
          pool.alert
        }}</b-alert>
      </div>
      <div v-if="pool.intro" v-html="pool.intro" />
      <b-form-group label class="radio-button-toggle">
        <b-form-radio-group
          id="btn-radios-2"
          v-model="overviewType"
          :options="overviewTypeList"
          buttons
          button-variant="secondary"
          name="radio-btn-secondary"
        ></b-form-radio-group>
      </b-form-group>
      <activity-view v-if="overviewType == 2" :pool="poolSlug" />
      <day-view v-if="overviewType == 1" :pool="poolSlug" />

      <div v-if="pool.bottomtext" v-html="pool.bottomtext" />
    </div>
  </div>
</template>

<script>
import ActivityView from "@/components/ActivityView.vue";
import DayView from "@/components/DayView.vue";
import Loader from "@/components/Loader.vue";
import axios from "axios";

export default {
  data() {
    return {
      loading: true,
      pool: {},
      overviewTypeListTemplate: [
        {
          text: "by_day",
          value: "1",
        },
        {
          text: "by_activity",
          value: "2",
        },
      ],
      lang: "nl",
      overviewType: "1",
    };
  },
  components: {
    ActivityView,
    DayView,
    Loader,
  },
  mounted: function () {
    this.lang = this.$route.params.lang;
    axios
      .get(this.poolsUrl)
      .then(
        (response) => ((this.pool = response.data), (this.loading = false))
      );
  },
  computed: {
    poolSlug() {
      return this.$route.params.pool;
    },
    poolsUrl() {
      return this.apiUrl + this.$route.params.pool + "/";
    },
    apiUrl() {
      return this.$store.state.apiDomain + this.$route.params.lang + "/api/";
    },
    text() {
      return this.$store.state.text[this.$route.params.lang];
    },
    overviewTypeList() {
      return this.overviewTypeListTemplate.filter(
        (item) => (item.text = this.text[item.text])
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.stickybar {
  position: sticky;
  z-index: 9000;
  top: 0;
}
</style>