<template>
<div class="mt-3 tabs-wrap">
    <b-tabs content-class="mt-3">
        <b-tab :title="text.today" active>
            <time-table :title="text.today" :url="todayUrl" />
            <b-button v-if="oneDay" variant="primary blue mb-3" @click="oneDay=false">{{ text.show_all_week}}</b-button>
            <div v-else>
                <time-table :title="text.tomorrow" :url="tomorrowUrl" />
                <time-table v-for="weekday in week" v-bind:key="weekday.url" :title="weekday.title" :url="weekday.url" />
            </div>
        </b-tab>
        <b-tab :title="text.tomorrow">
            <time-table :title="text.tomorrow" :url="tomorrowUrl" />
            <b-button v-if="oneDay" variant="primary blue mb-3" @click="oneDay=false">{{ text.show_all_week}}</b-button>
            <div v-else>
                <time-table v-for="weekday in week_from_tomrrow" v-bind:key="weekday.url" :title="weekday.title" :url="weekday.url" />
            </div>
        </b-tab>
        <b-tab :title="text.other_date">
            <p>
                <strong>{{ text.choose_date}}:</strong>
            </p>
            <b-form-datepicker id="primary-data-picker" v-model="value" :hide-header="true" :locale="$route.params.lang" :placeholder="text.choose_date"></b-form-datepicker>
            <time-table v-if="value!=null" :title="new Date(value).toLocaleDateString( locale, dateOptions)" :url="dateUrl" />
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import TimeTable from "@/components/TimeTable.vue";

export default {
    data() {
        return {
            oneDay: true,
            value: null,
            dateOptions: {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            },
        };
    },
    props: ["pool"],
    components: {
        TimeTable,
    },
    computed: {
        week() {
            var week = [];
            var date = new Date();
            date.setDate(date.getDate() + 2);

            for (var i = 0; i < 5; i++) {
                week.push({
                    title: new Date(date.toISOString().slice(0, 10)).toLocaleDateString(
                        this.locale,
                        this.dateOptions
                    ),
                    url: this.apiUrl + this.pool + "/date/" + date.toISOString().slice(0, 10) + "/",
                });
                date.setDate(date.getDate() + 1);
            }

            return week;
        },

        week_from_tomrrow() {
            var week = [];
            var date = new Date();
            date.setDate(date.getDate() + 2);

            for (var i = 0; i < 6; i++) {
                week.push({
                    title: new Date(date.toISOString().slice(0, 10)).toLocaleDateString(
                        this.locale,
                        this.dateOptions
                    ),
                    url: this.apiUrl + this.pool + "/date/" + date.toISOString().slice(0, 10) + "/",
                });
                date.setDate(date.getDate() + 1);
            }

            return week;
        },
        todayUrl() {
            return this.apiUrl + this.pool + "/date/today/";
        },
        tomorrowUrl() {
            return this.apiUrl + this.pool + "/date/tomorrow/";
        },
        dateUrl() {
            return this.apiUrl + this.pool + "/date/" + this.value + "/";
        },
        apiUrl() {
            return this.$store.state.apiDomain + this.$route.params.lang + "/api/";
        },
        text() {
            return this.$store.state.text[this.$route.params.lang];
        },
        locale() {
            return this.$route.params.lang == "en" ? "en-GB" : "nl-NL";
        },
    },
};
</script>
