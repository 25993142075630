<template>
  <div>
    <div style="padding-top:10px; margin-bottom: 10px;">
      <span v-if="selected">You have selected {{ selected }}</span>
    </div>
    <div>
      <vue-autosuggest
        :limit="5"
        :suggestions="filteredOptions"
        :input-props="inputProps"
        @input="onInputChange"
        @selected="onSelected"
      >
        <template slot="before-section-1" slot-scope="{section, className}">
          <li :class="className">
            A Custom Section
            <strong style="color: blue;">Before</strong>
          </li>
        </template>
        <template slot="after-section" slot-scope="{section}">
          <li style="padding-left:19px;">
            <strong>...</strong>
          </li>
        </template>
      </vue-autosuggest>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      selected: "",
      limit: 5,
      query: "",
      options: [
        {
          data: [
            "Frodo",
            "Gandalf",
            "Samwise",
            "Aragorn",
            "Galadriel",
            "Sauron",
            "Gimli",
            "Legolas",
            "Saruman",
            "Elrond",
            "Gollum",
            "Bilbo",
          ],
        },
      ],
      inputProps: {
        id: "autosuggest__input",
        placeholder: "Data",
      },
    };
  },
  computed: {
    filteredOptions() {
      const suggestionsData = this.options[0].data.filter((item) => {
        return item.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
      });

      return [
        {
          label: "Section",
          data: suggestionsData,
          limit: 2,
        },
        {
          label: "Section",
          data: suggestionsData,
          limit: 3,
        },
      ];
    },
  },
  sectionConfigs: {
    default: {
      limit: 6,
      onSelected: function (item, originalInput) {
      },
    },
    blog: {
      limit: 3,
      type: "url-section",
      onSelected: function () {
      },
    },
  },
  methods: {
    rando() {
      return Math.floor(Math.random() * (100 - 1)) + 1;
    },
    onSelected(item) {
      this.query = item.item;
    },
    onInputChange(text) {
      if (text === null) {
        return;
      }
      this.query = text;
    },
  },
};
</script>
