<template>
<table role="table" aria-busy="false" aria-colcount="2" class="table b-table decorated">
    <thead role="rowgroup" class>
        <tr role="row" class>
            <th role="columnheader" scope="col" aria-colindex="1" v-for="(item,index) in items[0]" v-bind:key="index">
                <div>{{ index }}</div>
            </th>
        </tr>
    </thead>
    <tbody role="rowgroup">
        <tr role="row" v-for="(item,index) in items" v-bind:key="index">
            <td aria-colindex="1" role="cell" v-for="(col,index) in item" v-bind:key="index" v-html="col" />
        </tr>
    </tbody>
</table>
</template>

<script>
export default {
    props: ["items"],
};
</script>
