<template>
  <div>
    <Loader v-if="loading" />
    <div v-else>
      <!-- <h1>{{ pool.title }}</h1> -->
      <!-- <div class="stickybar">
        <b-alert v-if="pool.alert" show dismissible variant="important">{{
          pool.alert
        }}</b-alert>
      </div> -->
      <!-- <div v-if="pool.intro" v-html="pool.intro" /> -->
      <activity-view :pool="poolSlug" :activity="activitySlug" />

      <!-- <div v-if="pool.bottomtext" v-html="pool.bottomtext" /> -->
    </div>
  </div>
</template>

<script>
import ActivityView from "@/components/ActivityView.vue";
import Loader from "@/components/Loader.vue";
import axios from "axios";

export default {
  data() {
    return {
      loading: true,
      pool: {},
      lang: "nl",
    };
  },
  components: {
    ActivityView,
    Loader,
  },
  mounted: function () {
    this.lang = this.$route.params.lang;
    axios
      .get(this.poolsUrl)
      .then(
        (response) => ((this.pool = response.data), (this.loading = false))
      );
  },
  computed: {
    activitySlug() {
      return this.$route.params.activity;
    },
    poolSlug() {
      return this.$route.params.pool;
    },
    poolsUrl() {
      return this.apiUrl + this.$route.params.pool + "/";
    },
    apiUrl() {
      return this.$store.state.apiDomain + this.$route.params.lang + "/api/";
    },
    text() {
      return this.$store.state.text[this.$route.params.lang];
    },
  },
};
</script>
<style lang="scss" scoped>
.stickybar {
  position: sticky;
  z-index: 9000;
  top: 0;
}
</style>