<template>
  <div class="Home">
    <div class="group">
      <h1 class="h1">Heading H1</h1>
      <h2 class="h2">Heading H2</h2>
      <h3 class="h3">Heading H3</h3>
      <h4 class="h4">Heading H4</h4>
      <h5 class="h5">Heading H5</h5>
      <h6 class="h6">Heading H6</h6>
    </div>
    <div class="group">
      <p>paragraph</p>
      <p class="p2">paragraph 2</p>
    </div>
    <div class="group">
      <h2>Buttons</h2>
      <div class="mt-3">
        <b-button variant="primary blue">Primary blue</b-button>
        <b-button variant="primary red">Primary red</b-button>
      </div>
      <div class="mt-3">
        <b-button variant="primary blue">
          <b-icon icon="arrow-bar-up" aria-hidden="true"></b-icon>Primary blue
        </b-button>
        <b-button variant="primary red">Primary red</b-button>
      </div>
      <div class="mt-3">
        <b-button variant="success">Success</b-button>
        <b-button variant="danger">Danger</b-button>
        <b-button variant="warning">Warning</b-button>
        <b-button disabled variant="disabled">Disabled</b-button>
      </div>
    </div>
    <div class="group">
      <h2>Buttons secondary (outline)</h2>
      <div class="mt-3">
        <b-button variant="outline-primary">Secondary blue</b-button>
      </div>
      <div class="mt-3">
        <b-button variant="outline-primary">
          <b-icon icon="arrow-bar-up" aria-hidden="true"></b-icon>Secondary blue
          with icon
        </b-button>
      </div>
      <div class="mt-3">
        <b-button disabled variant="outline-primary"
          >Secondary blue disabled</b-button
        >
      </div>
      <div class="mt-3">
        <b-button disabled variant="outline-primary">
          <b-icon icon="arrow-bar-up" aria-hidden="true"></b-icon>Secondary blue
          disabled with icon
        </b-button>
      </div>
    </div>
    <div class="group">
      <h2>Buttons tertiary</h2>
      <div class="mt-3">
        <b-button variant="tertiary">Tertiary</b-button>
      </div>
      <div class="mt-3">
        <b-button variant="tertiary">
          <b-icon icon="arrow-bar-up" aria-hidden="true"></b-icon>Tertiary with
          icon
        </b-button>
      </div>
      <div class="mt-3">
        <b-button disabled variant="tertiary">Tertiary disabled</b-button>
      </div>
      <div class="mt-3">
        <b-button disabled variant="tertiary">
          <b-icon icon="arrow-bar-up" aria-hidden="true"></b-icon>Tertiary
          disabled with icon
        </b-button>
      </div>
    </div>
    <div class="group">
      <div class="mt-3">
        <h2>Button taskflow</h2>
      </div>
      <div class="mt-3">
        <b-button variant="red taskflow">Button label</b-button>
      </div>
      <div class="mt-3">
        <b-button variant="blue taskflow">Button label</b-button>
      </div>
      <div class="mt-3">
        <h2>Blockquote</h2>
      </div>
      <div class="mt-3">
        <blockquote class="blockquote sm">
          Dit is een voorbeeld van een quote op desktop. Het rode quote icoon
          wordt alleen links getoond en is links uitgelijnd in een gridkolom. De
          tekst springt in.
        </blockquote>
      </div>

      <div class="mt-3">
        <blockquote class="blockquote">
          Dit is een voorbeeld van een quote op desktop. Het rode quote icoon
          wordt alleen links getoond en is links uitgelijnd in een gridkolom. De
          tekst springt in.
        </blockquote>
      </div>

      <div class="mt-3">
        <h2>Accordion</h2>
      </div>
      <div class="mt-3">
        <div role="tablist" class="accordion">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle.accordion-1 variant
                >Accordion ingeklapt met meer tekst dan dat zichtbaar is in
                dit</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text
                  >U kunt langskomen bij een van Stadsloketten, van maandag tot
                  en met vrijdag van 08.00 tot 19.30 uur. U kunt geen afspraak
                  maken voor de aanvraag. U vult ter plekke het
                  aanvraagformulier in en neemt de gevraagde documenten
                  mee.</b-card-text
                >
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <div class="mt-3">
        <div role="tablist" class="accordion">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle.accordion-2 variant
                >Accordion ingeklapt met meer tekst dan dat zichtbaar is in
                dit</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-2"
              visible
              accordion="my-accordion2"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text
                  >U kunt langskomen bij een van Stadsloketten, van maandag tot
                  en met vrijdag van 08.00 tot 19.30 uur. U kunt geen afspraak
                  maken voor de aanvraag. U vult ter plekke het
                  aanvraagformulier in en neemt de gevraagde documenten
                  mee.</b-card-text
                >
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <div class="mt-3">
        <div role="tablist" class="accordion">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-0" role="tab">
              <b-button block v-b-toggle.accordion-3 variant
                >Accordion ingeklapt met meer tekst dan dat zichtbaar is in
                dit</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-3"
              visible
              accordion="my-accordion3"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text
                  >U kunt langskomen bij een van Stadsloketten, van maandag tot
                  en met vrijdag van 08.00 tot 19.30 uur. U kunt geen afspraak
                  maken voor de aanvraag. U vult ter plekke het
                  aanvraagformulier in en neemt de gevraagde documenten
                  mee.</b-card-text
                >
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>

      <div class="mt-3">
        <h2>Alert</h2>
      </div>
      <div class="mt-3">
        <h2>Alert outline</h2>
      </div>
      <div class="mt-3">
        <b-alert show variant="outline">
          Dit is een voorbeeld van een stukje tekst dat je ten opzichte van
          andere tekst op de pagina meer nadruk wilt geven. Dat kun je doen door
          de tekst in een kader te plaatsen, zoals hier is gedaan. Maar er zijn
          nog twee andere varianten van een call-out toegestaan.
        </b-alert>
      </div>
      <div class="mt-3">
        <h2>Alert outline with heading</h2>
      </div>
      <div class="mt-3">
        <b-alert show variant="outline">
          <h3 class="alert-heading">Titel alert</h3>
          Dit is een voorbeeld van een stukje tekst dat je ten opzichte van
          andere tekst op de pagina meer nadruk wilt geven. Dat kun je doen door
          de tekst in een kader te plaatsen, zoals hier is gedaan. Maar er zijn
          nog twee andere varianten van een call-out toegestaan.
        </b-alert>
      </div>
      <div class="mt-3">
        <h2>Alert outline with icon</h2>
      </div>
      <div class="mt-3">
        <b-alert show variant="outline with-icon">
          Dit is een voorbeeld van een stukje tekst dat je ten opzichte van
          andere tekst op de pagina meer nadruk wilt geven. Dat kun je doen door
          de tekst in een kader te plaatsen, zoals hier is gedaan. Maar er zijn
          nog twee andere varianten van een call-out toegestaan.
        </b-alert>
      </div>
      <div class="mt-3">
        <h2>Alert background with icon</h2>
      </div>
      <div class="mt-3">
        <b-alert show variant="outline with-icon with-background">
          Dit is een voorbeeld van een stukje tekst dat je ten opzichte van
          andere tekst op de pagina meer nadruk wilt geven. Dat kun je doen door
          de tekst in een kader te plaatsen, zoals hier is gedaan. Maar er zijn
          nog twee andere varianten van een call-out toegestaan.
        </b-alert>
      </div>
      <div class="mt-3">
        <h2>Alert important</h2>
      </div>
      <div class="mt-3">
        <b-alert show dismissible variant="important"
          >Hier kan een melding komen voor de bezoeker van informatieve
          aard.</b-alert
        >
      </div>

      <div class="mt-3">
        <h2>Attention primary</h2>
      </div>
      <div class="mt-3">
        <b-alert show variant="attention attention-primary">
          Dit is een voorbeeld van een stukje tekst dat je ten opzichte van
          andere tekst op de pagina meer nadruk wilt geven. Dat kun je doen door
          de tekst in te springen en er een verticale lijn voor te plaatsen,
          zoals hier is gedaan. Maar er zijn nog twee andere varianten van een
          call-out toegestaan. De lijn is even hoog als het tekstblok.
        </b-alert>
      </div>
      <div class="mt-3">
        <h2>Attention secondary</h2>
      </div>
      <div class="mt-3">
        <b-alert show variant="attention attention-secondary">
          <h3 class="attention-heading">Titel Attention</h3>
          Dit is een voorbeeld van een stukje tekst dat je ten opzichte van
          andere tekst op de pagina meer nadruk wilt geven. Dat kun je doen door
          de tekst in te springen en er een verticale lijn voor te plaatsen,
          zoals hier is gedaan. Maar er zijn nog twee andere varianten van een
          call-out toegestaan. De lijn is even hoog als het tekstblok.
        </b-alert>
      </div>
      <div class="mt-3">
        <h2>Select</h2>
      </div>
      <div class="mt-3">
        <b-form-select v-model="selected" :options="myOptions">
          <!-- This slot appears above the options from 'options' prop -->
          <template v-slot:first>
            <b-form-select-option :value="null" disabled
              >Maak een keuze</b-form-select-option
            >
          </template>
          <b-form-select-option value="A"
            >Buitenlandse akten inleveren</b-form-select-option
          >
          <b-form-select-option value="B"
            >Huwelijk of partnerschap: aankondigen</b-form-select-option
          >
          <b-form-select-option value="C"
            >Partnerschap omzetten in huwelijk</b-form-select-option
          >
          <b-form-select-option value="D"
            >Verklaring huwelijksbevoegdheid aanvragen</b-form-select-option
          >
          <b-form-select-option value="E"
            >Akte Burgerlijke Stand opvragen</b-form-select-option
          >
        </b-form-select>
      </div>
      <div class="mt-5 pb-5">
        <div>
          <Select2
            v-model="myValue"
            :options="myOptions"
            :settings="{
              placeholder: 'Maak een keuze',
              minimumResultsForSearch: Infinity,
            }"
            @change="myChangeEvent($event)"
            @select="mySelectEvent($event)"
          />
        </div>
      </div>
      <div class="mt-3">
        <h2>Tabs</h2>
      </div>
      <div class="mt-3 tabs-wrap">
        <b-tabs content-class="mt-3">
          <b-tab title="Item 1" active>
            <p>Item 1</p>
          </b-tab>
          <b-tab title="Item 2">
            <p>Item 2</p>
          </b-tab>
          <b-tab title="Item 3">
            <p>Item 3</p>
          </b-tab>
          <b-tab title="Item 3">
            <p>Item 3</p>
          </b-tab>
          <b-tab title="Item 3">
            <p>Item 3</p>
          </b-tab>
          <b-tab title="Item 3">
            <p>Item 3</p>
          </b-tab>
          <b-tab title="Item 3">
            <p>Item 3</p>
          </b-tab>
        </b-tabs>
      </div>
      <div class="mt-5">
        <h2>Details</h2>
      </div>
      <div class="mt-3">
        <!-- Using modifiers -->
        <span v-b-toggle.collapse-target class="action-details"
          >Waarom wordt mijn geboortedatum gevraagd?</span
        >

        <!-- Element to collapse -->
        <b-collapse id="collapse-target" class="details-content">
          De toelichting wordt in de uitklap getoond. De toelichting is een vorm
          van help, net als instructietekst. Je kunt er best wel wat tekst in
          kwijt, want de maximale leesregellengte is 620px. Als je wilt kun je
          in zo’n toelichting dus een heleboel tekst en uitleg kwijt. En zelfs
          meer dan een paragraaf met tekst vullen. Alles wat lager op de pagina
          staat moet door deze uitklap naar beneden worden opgeschoven.
        </b-collapse>
      </div>
      <div class="mt-3">
        <!-- Using modifiers -->
        <span v-b-toggle.collapse-target2 class="action-details"
          >Waarom wordt mijn geboortedatum gevraagd?</span
        >

        <!-- Element to collapse -->
        <b-collapse id="collapse-target2" class="details-content">
          De toelichting wordt in de uitklap getoond. De toelichting is een vorm
          van help, net als instructietekst. Je kunt er best wel wat tekst in
          kwijt, want de maximale leesregellengte is 620px. Als je wilt kun je
          in zo’n toelichting dus een heleboel tekst en uitleg kwijt. En zelfs
          meer dan een paragraaf met tekst vullen. Alles wat lager op de pagina
          staat moet door deze uitklap naar beneden worden opgeschoven.
        </b-collapse>
      </div>
      <div class="mt-3">
        <!-- Element to collapse -->
        <b-collapse id="collapse-target3" class="details-content">
          De toelichting wordt in de uitklap getoond. De toelichting is een vorm
          van help, net als instructietekst. Je kunt er best wel wat tekst in
          kwijt, want de maximale leesregellengte is 620px. Als je wilt kun je
          in zo’n toelichting dus een heleboel tekst en uitleg kwijt. En zelfs
          meer dan een paragraaf met tekst vullen. Alles wat lager op de pagina
          staat moet door deze uitklap naar beneden worden opgeschoven.
        </b-collapse>

        <!-- Using modifiers -->
        <span v-b-toggle.collapse-target3 class="action-details"
          >Waarom wordt mijn geboortedatum gevraagd?</span
        >
      </div>

      <div class="mt-3">
        <h2>Toggle</h2>
      </div>
      <div class="mt-3">
        <template>
          <div>
            <b-form-group label class="radio-button-toggle">
              <b-form-radio-group
                id="btn-radios-2"
                v-model="selected"
                :options="newOptions"
                buttons
                button-variant="secondary"
                name="radio-btn-secondary"
              ></b-form-radio-group>
            </b-form-group>
          </div>
        </template>
      </div>
      <div class="mt-3">
        <h2>Datepicker</h2>
      </div>
      <div class="mt-3">
        <b-form-datepicker
          id="primary-data-picker"
          v-model="value"
          :hide-header="true"
          locale="en"
          placeholder="Waarde input field"
        ></b-form-datepicker>
      </div>
      <div class="mt-3">Table</div>
      <div class="mt-3">
        <b-table :data="data" :columns="columns"></b-table>
      </div>
      <div class="mt-3">
        <b-table :data="data" :columns="columns" class="decorated"></b-table>
      </div>
      <div class="mt-3">
        <b-table :data="data" striped>
          <template slot-scope="props">
            <b-table-column field="id" label="ID" width="40" numeric>{{
              props.row.id
            }}</b-table-column>

            <b-table-column field="first_name" label="First Name">{{
              props.row.first_name
            }}</b-table-column>

            <b-table-column field="last_name" label="Last Name">{{
              props.row.last_name
            }}</b-table-column>

            <b-table-column field="date" label="Date">
              <span class="tag is-success">{{
                new Date(props.row.date).toLocaleDateString()
              }}</span>
            </b-table-column>

            <b-table-column label="Gender">{{
              props.row.gender
            }}</b-table-column>

            <b-table-column label="Action">
              <a href="#" class="button is-primary is-small">Link</a>
            </b-table-column>
          </template>
        </b-table>
      </div>
      <div class="mt-3">
        <vue-autosuggest
          v-model="query"
          :suggestions="filteredOptions"
          @focus="focusMe"
          @click="clickHandler"
          @input="onInputChange"
          @selected="onSelected"
          :get-suggestion-value="getSuggestionValue"
          :input-props="{ id: 'autosuggest__input', placeholder: '' }"
        >
          <!-- <div slot-scope="{suggestion}" style="display: flex; align-items: center;">
            <div class="list-item">{{suggestion.item.name}}</div>
          </div>-->
          <div
            slot-scope="{ suggestion }"
            style="display: flex; align-items: center"
          >
            <div class="list-item">
              {{ suggestion.item.name }}
              <span class="link">{{ suggestion.item.link }}</span>
            </div>
          </div>
        </vue-autosuggest>
      </div>
    </div>
  </div>
</template>

<script>
import itemTemplate from "../item-template.vue";
export default {
  data() {
    return {
      query: "",
      selected: "",
      limit: 15,
      placeholder: "Do you feel lucky, punk?",
      suggestions: [
        {
          data: [
            {
              id: 1,
              name: "Zeeburgerpad (Oost)",
              race: "Hobbit",
              link:
                "https://www.amsterdam.nl/projecten/zeeburgerpad/zeeburgerpad-(oost)/",
            },
            {
              id: 2,
              name: "Oud-Oost",
              race: "Hobbit",
              link: "https://www.amsterdam.nl/oudoost/",
            },
            {
              id: 3,
              name: "Fietsverbinding Oost",
              race: "Maia",
              link: "https://www.amsterdam.nl/projecten/fietsverbinding-oost/",
            },
            {
              id: 4,
              name: "Herengracht oost",
              race: "Human",
              link:
                "https://www.amsterdam.nl/projecten/herengracht-oost/herengracht-oost-2/",
            },
            {
              id: 5,
              name: "Stadsdeel Oost",
              race: "Human",
              link:
                "https://www.amsterdam.nl/bestuur-organisatie/stadsdelen/stadsdeeloost/",
            },
          ],
        },
      ],
      value: "",
      myValue: "",
      myOptions: [
        "Buitenlandse akten inleveren",
        "Huwelijk of partnerschap: aankondigen",
        "Partnerschap omzetten in huwelijk",
        "Verklaring huwelijksbevoegdheid aanvragen",
        "Akte Burgerlijke Stand opvragen",
      ],
      sizes: [
        "Small",
        "Smaller",
        "Smallest",
        "Small",
        "Medium",
        "Large",
        "Extra Large",
      ],
      methods: {
        dateDisabled(ymd, date) {
          // Disable weekends (Sunday = `0`, Saturday = `6`) and
          // disable days that fall on the 13th of the month
          const weekday = date.getDay();
          const day = date.getDate();
          // Return `true` if the date should be disabled
          return weekday === 0 || weekday === 6 || day === 13;
        },
      },
      selected: "radio1",
      newOptions: [
        { text: "Dag", value: "radio1" },
        { text: "Week", value: "radio2" },
      ],
      data: [
        {
          id: 1,
          first_name: "Jesse",
          last_name: "Simmons",
          date: "2016-10-15 13:43:27",
          gender: "Male",
        },
        {
          id: 2,
          first_name: "John",
          last_name: "Jacobs",
          date: "2016-12-15 06:00:53",
          gender: "Male",
        },
        {
          id: 3,
          first_name: "Tina",
          last_name: "Gilbert",
          date: "2016-04-26 06:26:28",
          gender: "Female",
        },
        {
          id: 4,
          first_name: "Clarence",
          last_name: "Flores",
          date: "2016-04-10 10:28:46",
          gender: "Male",
        },
        {
          id: 5,
          first_name: "Anne",
          last_name: "Lee",
          date: "2016-12-06 14:38:38",
          gender: "Female",
        },
      ],
      columns: [
        {
          field: "id",
          label: "ID",
          width: "40",
          numeric: true,
        },
        {
          field: "first_name",
          label: "First Name",
        },
        {
          field: "last_name",
          label: "Last Name",
        },
        {
          field: "date",
          label: "Date",
        },
        {
          field: "gender",
          label: "Gender",
        },
      ],
    };
  },
  computed: {
    filteredOptions() {
      return [
        {
          data: this.suggestions[0].data
            .filter((option) => {
              return (
                option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
              );
            })
            .slice(0, this.limit),
        },
      ];
    },
  },
  methods: {
    myChangeEvent(val) {
      //    console.log(val);
    },
    mySelectEvent({ id, text }) {
      //     console.log({ id, text });
    },
    clickHandler(item) {
      // event fired when clicking on the input
    },
    onSelected(item) {
      this.selected = item.item;
    },
    onInputChange(text) {
      // event fired when the input changes
      //    console.log(text);
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    focusMe(e) {
      //   console.log(e); // FocusEvent
    },
  },
};
</script>

