<template>
  <div>
    <Loader v-if="loading" />
    <div
      v-if="!loading"
      class="mt-3"
    >
      <h1>{{ title }}</h1>
      <div
        v-if="alert"
        class="mt-3"
      >
        <b-alert
          show
          dismissible
          variant="important"
        >
          {{ alert }}
        </b-alert>
      </div>
      <AmsterdamTable :items="rows" />
      <p v-if="rows.length == 0">
        <em>{{ text.no_activities }}</em>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/Loader.vue'
import AmsterdamTable from '@/components/Table.vue'

export default {
  components: {
    Loader,
    AmsterdamTable,
  },
  props: ['title', 'url'],
  data() {
    return {
      loading: true,
      alert: null, // 'Dit is een testversie',
      rawRows: [],
    }
  },

  computed: {
    rows() {
      const activityTitle = this.$route.params.lang === 'en' ? 'Activity' : 'Activiteit'

      const timeSlotTitle = this.$route.params.lang === 'en' ? 'Time slot' : 'Tijdsslot'
      return this.rawRows.map((row) => {
        return {
          [activityTitle]: `<strong>${row.activity}</strong>${row.extra !== '' ? `<br/>${row.extra}` : ''}`,
          [timeSlotTitle]: `${row.start}&nbsp;-&nbsp;${row.end}`,
        }
      })
    },
    text() {
      return this.$store.state.text[this.$route.params.lang]
    },
  },

  watch: {
    url(newVal, oldVal) {
      // watch it
      this.loading = true
      this.loadData()
    },
  },
  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      axios
      .get(this.url)
      .then(
        (response) => (
          (this.rawRows = response.data.schedule), (this.loading = false)
        ),
      )
    },
  },
}
</script>
