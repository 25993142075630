<template>
  <div>
    <Loader v-if="loading" />
    <div v-else>
      <div v-if="activityList.length">
        <p>
          <strong>{{ text.choose_activity }}:</strong>
        </p>
        <Select2
          v-if="activityList.length > 1"
          v-model="currentActivity"
          :options="activityList"
          :settings="{ placeholder: text.choose, minimumResultsForSearch: Infinity }"
        />
      </div>
      <div
        v-if="currentActivity !== ''"
        class="activitieslist"
      >
        <h1>{{ currentTitle }}</h1>
        <AmsterdamTable :items="activitySchedule" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import TimeTable from '@/components/TimeTable.vue'
import AmsterdamTable from '@/components/Table.vue'
import Loader from '@/components/Loader.vue'

export default {
  components: {
    TimeTable,
    Loader,
    AmsterdamTable,
  },
  props: ['pool', 'activity'],
  data() {
    return {
      loading: true,
      currentActivity: '0',
      givenActivity: null,
      activities: {},
    }
  },
  computed: {
    activityUrl() {
      const optionalActivityId = this.activity ? `${this.activity}/` : ''
      return `${this.apiUrl + this.pool}/activity/${optionalActivityId}`
    },
    apiUrl() {
      return `${this.$store.state.apiDomain + this.$route.params.lang}/api/`
    },
    activityList() {
      const simpleAct = (act) => ({ id: act.id.toString(), text: act.name })

      if (this.activity) {
        const mainActivity = [{ id: 0, text: `Alle ${this.givenActivity.title}` }]
        const subActivities = this.givenActivity?.activities.map((activity) => simpleAct(activity)) ?? []
        return subActivities.length ? mainActivity.concat(subActivities) : []
      }
      return this.activities.activities.map((activity) => (simpleAct(activity)))
    },
    text() {
      return this.$store.state.text[this.$route.params.lang]
    },
    currentTitle() {
      const allActivities = this.$route.params.lang === 'en' ? 'All activities' : 'Alle activiteiten'
      if (this.currentActivity !== '0')
      {
        return this.activityList.find(
          (act) => act.id === this.currentActivity,
        ).text
      }
      return this.activity ? this.givenActivity.title : allActivities
    },


    activitySchedule() {
      const res = []
      const dayTitle = this.$route.params.lang === 'en' ? 'Day' : 'Dag'

      const timeSlotTitle = this.$route.params.lang === 'en' ? 'Time slot' : 'Tijdsslot'
      let specialTitle = this.$route.params.lang === 'en' ? 'Activity' : 'Activiteit'

      if (this.activity) {
        specialTitle = this.$route.params.lang === 'en' ? 'Remarks' : 'Bijzonderheden'
      }
      const currentActivityGroup = this.activity ? this.givenActivity : this.activities

      if (currentActivityGroup.days) {
        currentActivityGroup.days.forEach((day) => {
          let activitiesOnDay = []
          if (this.activity)
          {
            // Activity filtering
            activitiesOnDay = day.schedule.filter((act) => (this.currentActivity !== '0'
              ? act.id === this.currentActivity
              : act.group === this.givenActivity.id))
          } else {
            // Normal schedule
            activitiesOnDay = day.schedule.filter((act) => (this.currentActivity !== '0'
              ? act.group === this.currentActivity
              : true))// this.givenActivity.id))
          }


          if (activitiesOnDay.length === 0) {
            res.push({
              [dayTitle]: day.name,
              [specialTitle]: '',

              [timeSlotTitle]: '-',
            })
          } else {
            let dayName = day.name
            activitiesOnDay
            .forEach((act) => {
              res.push({
                [dayTitle]: dayName,
                [specialTitle]: `<strong>${act.activity}</strong><br/>${act.extra}`,
                [timeSlotTitle]: `${act.start}&nbsp;-&nbsp;${act.end}`,
              })
              dayName = ''
            })
          }
        })
      }

      return res // this.activities.days.map(activity => ({"name" : activity.name}));
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      axios
      .get(this.activityUrl)
      .then(
        (response) => {
          if (this.activity) {
            this.givenActivity = response.data
          } else {
            this.activities = response.data
          }
        },
      )
      // .catch((err) => console.error(err))
      .finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
.select2-container {
    margin-bottom: 30px;
}
</style>
