import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home.vue";
import Activity from '@/views/Activity.vue';
import Suggest from "@/views/Suggest.vue";
import PoolSelector from "@/views/PoolSelector.vue";
import Zwembaden from "@/views/Zwembaden.vue";
import NotFound from "@/components/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "poolselector",
    component: PoolSelector,
    meta: {
      auth: false,
    },
  },
  // {
  //   path: "/library",
  //   name: "home",
  //   component: Home,
  //   meta: {
  //     auth: false,
  //   },
  // },
  {
    path: "/activity/:lang/:pool/:activity",
    name: "activity",
    component: Activity,
    meta: {
      auth: false,
    },
  },
  {
    path: "/schedule/:lang/:pool",
    name: "schedule",
    component: Zwembaden,
    meta: {
      auth: false,
    },
  },
  {
    path: "/suggest",
    name: "suggest",
    component: Suggest,
    meta: {
      auth: false,
    },
  },
];

const router = new VueRouter({
  mode: "abstract",
  base: process.env.BASE_URL,
  routes,
});

export default router;
