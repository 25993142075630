import Vue from "vue";
import Vuex from "vuex";

//import * as modules from "./modules";

if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, "find", {
    value: function(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true,
  });
}

Vue.use(Vuex);

const store = new Vuex.Store({
  //  modules,
  state: {
    apiDomain: "https://zwembaden.api-amsterdam.nl/",
    text: {
      nl: {
        today: "Vandaag",
        tomorrow: "Morgen",
        other_date: "Andere datum",
        choose: "Maak een keuze",
        choose_date: "Kies een datum",
        choose_activity: "Kies een activiteit",
        by_activity: "Activiteiten",
        by_day: "Op dag",
        no_activities: "Er zijn geen activiteiten bekend",
        show_all_week: "Toon de hele week",
      },
      en: {
        today: "Today",
        tomorrow: "Tomorrow",
        other_date: "Other date",
        choose: "Select an activity",
        choose_date: "Choose a date",
        choose_activity: "Select activity",
        by_activity: "Activities",
        by_day: "Dates",
        no_activities: "No activities",
        show_all_week: "Show all week",
      },
    },
  },
});

export default store;
