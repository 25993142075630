<template>
  <div id="app">
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const configUrl = document.getElementById('app').dataset.config

    // fetch json from url and assign to data variable
    fetch(configUrl)
    .then((response) => response.json())
    .then((data) => {
      const settings = data.applicatie['extra-velden']
      const { activity, pool, lang } = settings

      if (activity) this.$router.push({ name: 'activity', params: { pool, lang, activity } })
      else this.$router.push({ name: 'schedule', params: { pool, lang } })
    })
  },
}
</script>

<style lang="scss">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: antialiased;
}
</style>
