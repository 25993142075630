<template>
<div>
    <h1>Zwembaden</h1>
    <h2>Dutch</h2>
    <ul>
        <li v-for="pool in pools" v-bind:key="pool.url">
            <router-link :to="{ name: 'schedule', params: {pool: pool.slug, lang: 'nl' }}">{{ pool.name }}</router-link>
        </li>
    </ul>
    <h2>English</h2>
    <ul>
        <li v-for="pool in pools" v-bind:key="pool.url">
            <router-link :to="{ name: 'schedule', params: {pool: pool.slug, lang: 'en' }}">{{ pool.name }}</router-link>
        </li>
    </ul>
</div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            pools: [],
        };
    },
    mounted: function () {
        axios.get(this.poolsUrl).then((response) => (this.pools = response.data));
    },
    computed: {
        poolsUrl() {
            return this.apiUrl;
        },
        apiUrl() {
            return this.$store.state.apiDomain + "/api/";
        },
    },
};
</script>
