import Vue from 'vue'
import VueAutosuggest from 'vue-autosuggest'
import BootstrapVue, { BootstrapVueIcons } from 'bootstrap-vue'
import Select2 from 'v-select2-component'
import { Table } from 'buefy'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.component('Select2', Select2)

Vue.use(VueAutosuggest)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Table)

Vue.config.productionTip = false
Vue.prototype.$log = console.log

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app-loader')
